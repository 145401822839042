import React, { useState } from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import BackgroundImage from "gatsby-background-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ScrollWrapper from "../components/Scroll/ScrollWrapper";
import ScrollNavigation from "../components/Scroll/ScrollNavigation";
import ScrollContent from "../components/Scroll/ScrollContent";
import TestimonialBackgroundImage from "../components/Testimonial/TestimonialBackgroundImage";
import WhyUs from "../components/Repeating/WhyUs";
import CTABackgroundImage from "../components/CTA/CTABackgroundImage";
import ModalGallery from "../components/Modal/ModalGallery";
import ButtonSolid from "../components/Button/ButtonSolid";

const Page = ({ data }) => {
  const [slideIndex, setSlideIndex] = useState(0);

  const processBgImages = [
    data.processBgDesktop.childImageSharp.fixed,
    {
      ...data.processBgDesktop.childImageSharp.fixed,
      media: `(max-width: 767px)`,
    },
  ];

  const url = typeof window !== "undefined" ? window.location.pathname : "";

  return (
    <Layout
      headerStyle="standard"
      headerLinkColor="dark"
      headerHasBorder={false}
    >
      <SearchEngineOptimization
        title="Vacuum Forming & Thermoforming Company | Painted Rhino"
        description="Trust Painted Rhino for your vacuum forming and thermoforming services. We are based in California but serve nationwide. See what we can do and get a free quote!"
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="pt-6 md:pt-14 pb-20 md:pb-32">
        <div className="container">
          <header className="mb-12">
            <h1>Vacuum Forming and Thermoforming Services</h1>
          </header>
          <div className="mb-12 md:mb-28">
            <Img
              fadeIn={false}
              loading="eager"
              fluid={data.heroDesktop.childImageSharp.fluid}
              alt="Fiberglass & Composites"
            />
          </div>
          <div className="flex flex-col gap-y-10 lg:grid lg:grid-cols-2 lg:gap-x-20 items-center justify-center">
            <div>
              <h2>Your Trusted Plastic Molding Partner</h2>
              <p className="mb-0">
                Need to shape a plastic sheet into a specific form? Partner with
                Painted Rhino! From small, intricate parts to large, complex
                structures, we use both vacuum forming and thermoforming plastic
                molding techniques that create products with precision and
                efficiency. Whether you’re looking for:
              </p>
              <ul className="list-disc ml-4 my-5">
                <li>RV parts</li>
                <li>Battery trays</li>
                <li>Golf cart parts</li>
                <li>Industrial & commercial products</li>
                <li>Medical devices</li>
                <li>Automotive & mass transit parts</li>
                <li>Point of purchase displays</li>
                <li>Wall panels</li>
                <li>Light shades</li>
                <li>Signage</li>
                <li>Equipment housing</li>
              </ul>
              <p>
                Or any other component you can dream up, Painted Rhino has you
                covered.
              </p>
              <ButtonSolid
                as="button"
                data="modal-contact"
                text="Connect With Us"
              />
            </div>
            <div className="w-full h-full mt-20 ">
              <Img
                fadeIn={false}
                loading="eager"
                className=""
                fluid={data.intro.childImageSharp.fluid}
                alt="mx-auto "
              />
            </div>
          </div>
        </div>
      </section>

      <BackgroundImage
        fixed={processBgImages}
        className="w-full pt-22 md:pt-32 pb-18 md:pb-36"
      >
        <div className="container relative">
          <header className="mb-18 md:mb-22 max-w-sm">
            <h3>Our Vacuum Forming & Thermoforming Processes</h3>
          </header>
          <ScrollWrapper>
            <ScrollNavigation>
              <li>
                <AnchorLink
                  to={url + "#section-1"}
                  title="Heavy Gauge Thermoforming Process"
                  stripHash
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#section-2"}
                  title="Vacuum Forming"
                  stripHash
                />
              </li>
            </ScrollNavigation>
            <ScrollContent>
              <div
                id="section-1"
                className="md:bg-white md:pt-14 md:pb-12 md:px-14 mb-12 lg:mb-20"
              >
                <h4>Vacuum Forming: High Production, High Precision</h4>
                <p className="mb-0">
                  One of the plastic molding techniques we utilize is vacuum
                  forming, which involves heating plastic sheets until they are
                  pliable before using vacuum pressure to mold the sheet into a
                  desired shape. The result? A durable, high-quality part made
                  more quickly than other methods, including fiberglass.
                </p>
                <p>
                  This technique is ideal for high-volume production runs,
                  making it the perfect molding solution for industries that
                  need cost-effective solutions. Our vacuum forming company can
                  shape a variety of materials, including:
                </p>
                <ul className="list-disc ml-4 my-5">
                  <li>ABS</li>
                  <li>Petg</li>
                  <li>Acrylic</li>
                  <li>Styrene</li>
                  <li>Tpo</li>
                </ul>
                <p>
                  And we can form plastic parts with thicknesses ranging from
                  0.60 inches up to 0.375 inches, ensuring the precise needs of
                  your project are met.
                </p>
                <ButtonSolid
                  as="button"
                  data="modal-contact"
                  text="Discuss Vacuum Forming"
                />
              </div>
              <div
                id="section-2"
                className="md:bg-white md:pt-14 md:pb-12 md:px-14"
              >
                <h4>
                  Thermoforming: Where Large Parts & Complex Designs Meet
                  Gravity
                </h4>
                <p className="">
                  Thermoforming services are similar to vacuum forming in that
                  they both involve shaping heated plastic sheets. However,
                  where vacuum forming uses pressure, thermoforming uses
                  gravity. Ideal for larger, more complex designs and parts,
                  thermoforming is a great molding option for projects that
                  still require a high degree of precision but don’t necessarily
                  need the higher pressure of vacuum forming.
                </p>
                <p className="">
                  Thermoforming offers flexibility in both design and material
                  options, giving you the freedom to create highly specialized
                  projects. If you’re looking for:
                </p>
                <ul className="list-disc ml-4 my-5">
                  <li>Oversized parts</li>
                  <li>Commercial products</li>
                  <li>Equipment housings</li>
                </ul>
                <p className="">
                  Thermoforming may be your best solution. Connect with our team
                  of plastic molding experts to learn more.
                </p>
                <ButtonSolid
                  as="button"
                  data="modal-contact"
                  text="Get Thermoforming Assistance"
                />
              </div>
              <div
                id="section-3"
                className="md:bg-white md:pt-14 md:pb-12 md:px-14"
              >
                <h4>A Go-To Provider</h4>
                <p className="">
                  Here at Painted Rhino, we’re more than just a
                  manufacturer–we’re your partner in success, working with you
                  from concept to completion. As a go-to provider with one of
                  the largest vacuum forming machines on the West Coast
                  (measuring 7x12 feet), we can handle even the most demanding
                  projects, delivering exceptional results at scale.
                </p>
                <p className="">
                  From our fast turnarounds, ability to handle small and large
                  production runs with ease, and exceptional quality control,
                  it’s easy to see why Painted Rhino is a top choice for those
                  looking for vacuum forming or thermoforming needs. Have a
                  project you’re ready to bring to life? Request a quote today!
                </p>
                <ButtonSolid
                  as="button"
                  data="modal-contact"
                  text="Get a Quote"
                />
              </div>
            </ScrollContent>
          </ScrollWrapper>
        </div>
      </BackgroundImage>
      <WhyUs
        className="pt-22 md:pt-32 pb-20 md:pb-28"
        contentAlignment="md:text-center md:mx-auto"
        headerHeading="The Painted Rhino Difference."
      />
      <TestimonialBackgroundImage />

      <section className="py-20 md:py-32">
        <div className="container">
          <div className="grid md:grid-cols-2 gap-2 md:gap-4">
            <div className="grid grid-rows-2 grid-cols-10 gap-2 md:gap-4">
              <div className="col-start-1 col-span-6 row-span-1">
                <button
                  aria-label="Modal trigger"
                  data-modal-open="modal-gallery"
                  onClick={() => setSlideIndex(0)}
                >
                  <Img
                    fadeIn={false}
                    loading="eager"
                    className="w-full h-full"
                    fluid={data.galleryThumbnail1.childImageSharp.fluid}
                    alt=""
                  />
                </button>
              </div>
              <div className="col-end-11 col-span-4 row-span-1">
                <button
                  aria-label="Modal trigger"
                  data-modal-open="modal-gallery"
                  onClick={() => setSlideIndex(1)}
                >
                  <Img
                    fadeIn={false}
                    loading="eager"
                    className="w-full h-full"
                    fluid={data.galleryThumbnail2.childImageSharp.fluid}
                    alt=""
                  />
                </button>
              </div>
              <div className="col-start-1 col-span-4 row-span-1">
                <button
                  aria-label="Modal trigger"
                  data-modal-open="modal-gallery"
                  onClick={() => setSlideIndex(2)}
                >
                  <Img
                    fadeIn={false}
                    loading="eager"
                    className="w-full h-full"
                    fluid={data.galleryThumbnail3.childImageSharp.fluid}
                    alt=""
                  />
                </button>
              </div>
              <div className="col-end-11 col-span-6 row-span-1">
                <button
                  aria-label="Modal trigger"
                  data-modal-open="modal-gallery"
                  onClick={() => setSlideIndex(3)}
                >
                  <Img
                    fadeIn={false}
                    loading="eager"
                    className="w-full h-full"
                    fluid={data.galleryThumbnail4.childImageSharp.fluid}
                    alt=""
                  />
                </button>
              </div>
            </div>
            <div className="grid grid-rows-2 grid-cols-10 grid-flow-col gap-2 md:gap-4">
              <div className="col-span-4 row-span-2">
                <button
                  aria-label="Modal trigger"
                  data-modal-open="modal-gallery"
                  onClick={() => setSlideIndex(4)}
                >
                  <Img
                    fadeIn={false}
                    loading="eager"
                    className="w-full h-full"
                    fluid={data.galleryThumbnail5.childImageSharp.fluid}
                    alt=""
                  />
                </button>
              </div>
              <div className="col-span-6 row-span-1">
                <button
                  aria-label="Modal trigger"
                  data-modal-open="modal-gallery"
                  onClick={() => setSlideIndex(5)}
                >
                  <Img
                    fadeIn={false}
                    loading="eager"
                    className="w-full h-full"
                    fluid={data.galleryThumbnail6.childImageSharp.fluid}
                    alt=""
                  />
                </button>
              </div>
              <div className="row-span-1 col-span-6">
                <button
                  aria-label="Modal trigger"
                  data-modal-open="modal-gallery"
                  onClick={() => setSlideIndex(6)}
                >
                  <Img
                    fadeIn={false}
                    loading="eager"
                    className="w-full h-full"
                    fluid={data.galleryThumbnail7.childImageSharp.fluid}
                    alt=""
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <CTABackgroundImage />

      <ModalGallery slideIndex={slideIndex}>
        <div>
          <Img
            fadeIn={false}
            loading="eager"
            fluid={data.galleryLightbox1.childImageSharp.fluid}
            alt=""
          />
        </div>
        <div>
          <Img
            fadeIn={false}
            loading="eager"
            fluid={data.galleryLightbox2.childImageSharp.fluid}
            alt=""
          />
        </div>
        <div>
          <Img
            fadeIn={false}
            loading="eager"
            fluid={data.galleryLightbox3.childImageSharp.fluid}
            alt=""
          />
        </div>
        <div>
          <Img
            fadeIn={false}
            loading="eager"
            fluid={data.galleryLightbox4.childImageSharp.fluid}
            alt=""
          />
        </div>
        <div>
          <Img
            fadeIn={false}
            loading="eager"
            fluid={data.galleryLightbox5.childImageSharp.fluid}
            alt=""
          />
        </div>
        <div>
          <Img
            fadeIn={false}
            loading="eager"
            fluid={data.galleryLightbox6.childImageSharp.fluid}
            alt=""
          />
        </div>
        <div>
          <Img
            fadeIn={false}
            loading="eager"
            fluid={data.galleryLightbox7.childImageSharp.fluid}
            alt=""
          />
        </div>
      </ModalGallery>
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: {
        eq: "open-graph/facebook/Vacuum Forming _ Thermoforming_FB.jpg"
      }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: {
        eq: "open-graph/twitter/Vacuum Forming _ Thermoforming_Twitter.jpg"
      }
    ) {
      publicURL
    }
    heroDesktop: file(
      relativePath: { eq: "services/vacuum-forming-thermoforming.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    processBgDesktop: file(
      relativePath: { eq: "services/process-bg-desktop.jpg" }
    ) {
      childImageSharp {
        fixed(width: 2880, height: 1432) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    galleryThumbnail1: file(
      relativePath: {
        eq: "services/Vacuum-Forming-Thermoforming/Gallery/Thumbnails/Image 1.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 355) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    galleryLightbox1: file(
      relativePath: {
        eq: "services/Vacuum-Forming-Thermoforming/Gallery/Lightbox/Image 1.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxHeight: 800) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    galleryThumbnail2: file(
      relativePath: {
        eq: "services/Vacuum-Forming-Thermoforming/Gallery/Thumbnails/Image 2.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 221) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    galleryLightbox2: file(
      relativePath: {
        eq: "services/Vacuum-Forming-Thermoforming/Gallery/Lightbox/Image 2.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxHeight: 800) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    galleryThumbnail3: file(
      relativePath: {
        eq: "services/Vacuum-Forming-Thermoforming/Gallery/Thumbnails/Image 5.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 222) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    galleryLightbox3: file(
      relativePath: {
        eq: "services/Vacuum-Forming-Thermoforming/Gallery/Lightbox/Image 5.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxHeight: 800) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    galleryThumbnail4: file(
      relativePath: {
        eq: "services/Vacuum-Forming-Thermoforming/Gallery/Thumbnails/Image 6.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 354) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    galleryLightbox4: file(
      relativePath: {
        eq: "services/Vacuum-Forming-Thermoforming/Gallery/Lightbox/Image 6.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxHeight: 800) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    galleryThumbnail5: file(
      relativePath: {
        eq: "services/Vacuum-Forming-Thermoforming/Gallery/Thumbnails/Image 3.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 222) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    galleryLightbox5: file(
      relativePath: {
        eq: "services/Vacuum-Forming-Thermoforming/Gallery/Lightbox/Image 3.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxHeight: 800) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    galleryThumbnail6: file(
      relativePath: {
        eq: "services/Vacuum-Forming-Thermoforming/Gallery/Thumbnails/Image 4.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 354) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    galleryLightbox6: file(
      relativePath: {
        eq: "services/Vacuum-Forming-Thermoforming/Gallery/Lightbox/Image 4.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxHeight: 800) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    galleryThumbnail7: file(
      relativePath: {
        eq: "services/Vacuum-Forming-Thermoforming/Gallery/Thumbnails/Image 7.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 354) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    galleryLightbox7: file(
      relativePath: {
        eq: "services/Vacuum-Forming-Thermoforming/Gallery/Lightbox/Image 7.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxHeight: 800) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    intro: file(
      relativePath: { eq: "services/Vacuum-Forming-Thermoforming/intro.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 800) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`;

export default Page;
